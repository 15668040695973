import { Routes } from '@angular/router';
import { authGuard } from './helpers/guard/auth.guard';
import { NotFoundComponent } from './components/not-found/not-found.component';

export const routes: Routes = [
    {
        path: '',
        loadComponent: ()=>import('./components/voice-ai.component').then(m => m.VoiceAiComponent),
        children: [
            // {
            //     path: '',
            //     loadComponent:()=>import('./components/landing/landing.component').then(m => m.LandingComponent)
            // },
            {
                path: '',//'exploreFrd',
                loadComponent:()=>import('./components/ai-homepage/ai-homepage.component').then(m => m.AiHomepageComponent)
            },
            {
                path: 'friendList',
                loadComponent:()=>import('./components/user-friend-list/user-friend-list.component').then(m => m.UserFriendListComponent)
            },
            {
                path: 'chat/:id',
                loadComponent:()=>import('./components/agents-chat/agents-chat.component').then(m => m.AgentsChatComponent)
            },
            {
                path: 'voiceChat/:id',
                loadComponent:()=>import('./components/voice-chat/voice-chat.component').then(m => m.VoiceChatComponent)
            },
            {
                path: 'userProfile',
                loadComponent:()=>import('./components/user-profile/user-profile.component').then(m => m.UserProfileComponent)
            },
            {
                path: 'updateProfile',
                loadComponent:()=>import('./components/update-profile/update-profile.component').then(m => m.UpdateProfileComponent)
            },
            {
                path: 'add_update_character',
                loadComponent: ()=>import('./components/add-character/add-character.component').then(m => m.AddCharacterComponent)
            },
            {
                path: 'profileView/:id',
                loadComponent: ()=>import('./components/profile-view/profile-view.component').then(m => m.ProfileViewComponent)
            }
        ],
        canActivate: [authGuard]
    },
    {
        path:'verifyUser',
        loadComponent:()=> import('./authentication/verify-user/verify-user.component').then(m => m.VerifyUserComponent)
    },
    // {
    //     path: 'login',
    //     loadComponent: ()=>import('./authentication/auth-login/auth-login.component').then(m => m.AuthLoginComponent )
    // },
    // {
    //     path: 'register',
    //     loadComponent: ()=>import('./authentication/auth-register/auth-register.component').then(m => m.AuthRegisterComponent )
    // },
    {
        path: '**',
        component: NotFoundComponent
    }
];
